.main {
	font-family: "Poppins", sans-serif;

	.header__logos{
		margin: 1rem;
	}
	.background__img{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		// @media only screen and (max-width: 700px) {
		// 	top: 304px;
		// }
	}
}
