.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
        margin-inline: 1rem; 
    }
    
    .interest__loans{
        position: relative;
        border-radius: 15px;
        z-index: 2;
        margin-top: 70px;
        @media only screen and (max-width: 600px) {
            margin-top: 10px;
        }
    }

    .our__benefits {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 2rem;
        // gap: 3rem;
        position: relative;
        z-index: 2;
        margin-top: 10px;
        width: 100%;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
            gap: 0.5rem;
        }
    }

    .form__content{
        width: 100%;
        height: 300px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px;
        opacity: 1;
        position: relative;
        z-index: 2;
        margin-top: 15px;
        @media only screen and (max-width: 700px) {
            height: auto;
        }

        h3{
            color: #002347;
            text-align: center;
            margin-top: 20px;
            @media only screen and (max-width: 700px) {
                font-size: small;
                margin-top: 40px;
            }
        }

        .form__main{
            .form__fields{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 40px;
            }

            .text__field{
                width: 390px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #B8C6D5;
                border-radius: 5px;
                opacity: 1;
                color: #666666;

                &.text__field__error {
                    box-shadow: none; 
                    border: none;
                }

                @media only screen and (max-width: 700px) {
                    width: 100%;
                    ::placeholder{
                        font-size: small;
                    }
                }
            }

            .otp-input__main {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                margin-top: 20px;
                .otp {
                    display: flex;
                    flex-direction: column;
                    input {
                        width: 250px;
                        height: 40px;
                    }
                    a {
                        margin-top: 10px;
                        align-self: flex-end;
                        color: #0b58ff;
                    }
                }
            }

            .footer{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                .footer__btn{
                    width: 160px; 
                    color: #FFFFFF;
                    background-color: #5476FF;
                    font-weight: bold;
                    font-size: larger;
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 2rem;
                    @media only screen and (max-width: 700px) {
                        margin-top: 2rem;
                        position: static;
                    }
                }
            }
        }
    }
}

.result__main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
        margin-inline: 1rem; 
    }

    .result__content{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 488px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px;
        opacity: 1;
        position: relative;
        z-index: 2;
        margin-top: 70px;
        color: #002347;
        @media only screen and (max-width: 700px) {
            height: auto;
            margin-top: 10px;
        }

        .result__success{
            text-align: center;
            margin-top: 70px;
            @media only screen and (max-width: 600px) {
                margin: 1.5rem;
            }
            .result__title {
                margin-top: 25px;
                font-weight: 700;
                font-size: 18px;
                @media only screen and (max-width: 600px) {
                    font-size: 20px;
                }
        
            }
            .result__question{
                margin-top: 25px;
                font-weight: 700;
                font-size: 19px;
            }
            .checkbox {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                margin-bottom: 40px;
        
                label {
                  margin: 0 40px;

                  input {
                    margin-right: 10px;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #5476FF;
                  }

                  span {
                    font-weight: 700;
                    font-size: 16.5px;
                  }
                }
            }
            .checkbox__allen{
                flex-direction: column;
                align-items: flex-start;
            }
            .proceed__btn {
                width: 160px;
                color: #FFFFFF;
                background-color: #5476FF;
                font-weight: bold;
            }
        }
        .result__failed{
            text-align: center;
            margin-top: 150px;
            p{
                margin-top: 40px;
            }
        }
    }
}
